/* footer.css */
.footer {
    background-color: #f8f9fa; /* Light theme background color */
    color: #333; /* Light theme text color */
    padding: 20px 0;
    text-align: center;
}

.footer-links {
    margin-bottom: 20px;
}

.footer-links a {
    margin-right: 15px;
    color: #007bff; /* Light theme link color */
    text-decoration: none;
}

.footer-copy p {
    color: #6c757d; /* Light theme footer text color */
    margin: 0;
}

.footer-dark {
    background-color: #333; /* Dark theme background color */
    color: #fff; /* Dark theme text color */
}

.footer-dark .footer-links a {
    color: #fff; /* Dark theme link color */
}

/* Add any additional dark theme styles here */
