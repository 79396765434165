/* Contact.css */
.contact-page {
    text-align: center;
    padding: 20px;
}

.contact-page h1 {
    margin-bottom: 20px;
}

.contact-page p {
    margin-bottom: 10px;
    line-height: 1.6;
}

.contact-page a {
    color: #007bff;
    text-decoration: none;
}
