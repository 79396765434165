/* PrivacyPolicy.css */
.privacy-policy {
    padding: 20px;
    line-height: 1.6;
}

.privacy-policy h1, h2, h3 {
    color: #333;
}

.privacy-policy section {
    margin-bottom: 20px;
}
