/* HelpCenter.css */
.help-center {
    padding: 20px;
}

.help-center h1, h2, h3 {
    color: #333;
}

.help-center ul {
    list-style-type: none;
    padding: 0;
}

.help-center ul li {
    margin-bottom: 10px;
}

.help-center p {
    line-height: 1.6;
}
