/* TermsAndConditions.css */
.terms-conditions {
    padding: 20px;
    line-height: 1.6;
}

.terms-conditions h1, h2 {
    color: #333;
}

.terms-conditions section {
    margin-bottom: 20px;
}
