/* AboutUs.css */
.about-us {
    padding: 20px;
    line-height: 1.6;
}

.about-us h1 {
    font-size: 24px;
    margin-bottom: 15px;
}

.contact-info {
    margin-top: 30px;
    font-style: italic;
}
